<template>
  <div id="project">
    <a 
      class="close" 
      href="#" 
      @click.prevent="close"
      :style="{'zIndex': showMenu ? '0' : '3'}"
    >
    Закрыть</a>
    <!-- Component -->
    <keep-alive>
      <ProjectSocket
          :title="title"
          :link="link"
          :description="description"
          :pdfFiles="pdfFiles"
          :blocks="blocks"
          :blocksLoading="blocksLoading"
          :projectBtnLink="projectBtnLink"
          :showMenu="showMenu"
          @isOpenGallery="getIsOpenGallery"
      ></ProjectSocket>
    </keep-alive>
  </div>
</template>

<script>
import ProjectSocket from './ProjectSocket'

export default {
  name: "ProjectLayout",
  props: ['showMenu', 'id', 'language'],
  data: () => ({
    projectBtnLink: '',
    title: '',
    link: '',
    description: '',
    pdfFiles: [],
    blocks: [],
    blocksLoading: false
  }),
  components: {
    ProjectSocket
  },
  watch: {
    id(){
      ( async () => {
        if (this.id) {
          this.blocks = []
          this.pdfFiles = []
          const slides = this.language.pages.portfolio.slides
          const slide = slides[this.id - 1]
          if (slide.project) {
            this.title = slide.project?.title
            this.description = slide.project?.description
            this.link = slide.project?.link

            if (slide.project.pdfs){
              // eslint-disable-next-line no-unused-vars
              for await (let [_, value] of Object.entries(slide.project.pdfs)){
                if (value.visible) {
                  const url = await this.$store.dispatch('fetchMedia', {path: value.data})
                  this.pdfFiles.push({
                    link: url,
                    name: value.name
                  })
                }
              }
            }
            else {
              this.pdfFiles = []
            }

            if (slide.project.gallery){
              this.blocksLoading = true
              // eslint-disable-next-line no-unused-vars
              for await (let [_, value] of Object.entries(slide.project.gallery)){
                if (value.visible) {
                  if (value.type === 'image') {
                    const vPath = value.data.img || value.data
                    const url = await this.$store.dispatch('fetchMedia', {path: vPath})
                    this.blocks.push({
                      path: url,
                      description: value.description,
                      type: value.type
                    })
                  }
                  if (value.type === 'video') {
                    const vPath = value.data.video.preview || value.data.preview
                    const url = await this.$store.dispatch('fetchMedia', {path: vPath})
                    this.blocks.push({
                      path: url,
                      video: value.data.video.player || value.data.player,
                      description: value.description,
                      type: value.type
                    })
                  }
                  if (value.type === 'youtube') {
                    this.blocks.push({
                      path: value.data.link.split('watch?v=')[1],
                      preview: value.data.preview,
                      description: value.description,
                      type: value.type
                    })
                  }
                }
              }
              this.blocksLoading = false
            }
            else {
              this.blocks = []
            }
          }
          else {
            this.title = ''
            this.link = ''
            this.description = ''
            this.pdfFiles = []
            this.blocks = []
          }
        }
      })()
    }
  },
  updated() {
    if (this.$children[0]){
      this.$children.forEach( (e,i) => {
        if (e.$options._componentTag === this.project){
          this.$emit('projectBtnLink', this.$children[i].projectBtnLink)
        }
      })
    }
  },
  mounted() {
    if (this.$children[0]){
      this.$children.forEach( (e,i) => {
        if (e.$options._componentTag === this.project){
          this.$emit('projectBtnLink', this.$children[i].projectBtnLink)
        }
      })
    }
  },
  methods: {
    fetchContent(content){
      this.title = content.title || ''
      this.link = content.link || ''
      this.pdfFiles = content.pdf || []
      if (content.gallery){
        content.gallery.forEach(e => {
          if (e.type !== 'video'){
            this.blocks.push({path: e.data, type: e.type, description: e.text})
          }
          else {
            this.blocks.push({video: e.data, type: e.type, description: e.text, path: e.path || undefined})
          }
        })
      }
      else {
        this.blocks = []
      }
      this.description = content.text || ''
    },
    close(){
      this.$emit('clicked', false)
    },
    getIsOpenGallery(e){
      this.$emit('isOpenGallery', e)      
    }
  }
}
</script>

<style lang="sass">
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
$top: calc((68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320))))) / 2 - 16.2px)
#project
  position: relative
  min-height: 100%
  width: 100%
  padding: 120px $threshold 80px $threshold
  background-color: #fff
  color: #000
  .close
    position: fixed
    top: $top
    transition: 0.5s ease

    font-family: 'Galderglynn1884SqDb', sans-serif
    font-size: 27px
    letter-spacing: 0.02em
    text-transform: uppercase
    color: #000
    &:hover
      opacity: 0.7
</style>

