<template>
  <div
      class="project__gallery-block"
      @click="getSrc"
  >
    <div class="gallery__img-box">
      <img :src="path" alt="" class="gallery__img">
      <img
          v-if="blocks.type === 'youtube' || blocks.type === 'video'"
          src="../../assets/images/play.png"
          alt=""
          class="project__play"
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "GalleryBlocks",
  props: ['blocks'],
  data: () => ({
    path: ''
  }),
  methods: {
    getSrc() {
      this.$emit('isOpenGallery', true)
      this.$emit('source', this.blocks)
    }
  },
  mounted() {
    if (this.blocks.type === 'image' || this.blocks.type === 'video') {
      this.path = this.blocks.path
    }
    else if (this.blocks.type === 'youtube') {
      this.path = this.blocks.preview
    }
  }
}
</script>
<style lang="sass">
.project__gallery-block
  background-color: #000
  .gallery__img-box
    position: relative
    height: 100%
    width: 100%
    cursor: pointer
    opacity: 0.7
    box-sizing: content-box
    &:hover
      opacity: 1
  .gallery__img
    display: block
    object-fit: cover
.project__play
  position: absolute
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
</style>