<template>
  <div class="project__pdf">
    <div class="project__pdf-header" @click.prevent="isPdf = !isPdf">
      Pdf
      <svg width="30px" height="8px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 21.9 7.6" style="enable-background:new 0 0 21.9 7.6;" xml:space="preserve"
        :style="{transform: isPdf ? 'rotate(0deg)' : 'rotate(-90deg)'}"
      >
      <polygon points="11,7.6 0,1.8 0.9,0 11,5.3 21,0 21.9,1.8 "/>
      </svg>
    </div>
    <div class="project__pdf-files">
        <PdfFiles
            v-show="isPdf"
            v-for="(file, i) in pdfFiles"
            :key="i"
            :pdf="file"
            :style="{backgroundColor: i % 2 === 0 ? '#c4c4c420' : 'transparent'}"
        ></PdfFiles>
    </div>
    
  </div>
</template>

<script>
import PdfFiles from './PdfFiles'
export default {
  name: "Pdf",
  props: ['pdfFiles'],
  components: {
    PdfFiles
  },
  data: () => ({
    isPdf: false
  })
}
</script>

<style lang="sass">
$thresholdx2: calc(448px + 52 * ((100vw - 320px) / (1920 - 320)))
$thresholdMinus: calc(-1 * (20px + 15 * ((100vw - 320px) / (1920 - 320))))
.project__pdf
  .project__pdf-header
    display: flex
    justify-content: space-between
    align-items: center
    height: 60px

    text-align: center
    font-size: 28px
    font-family: 'Galderglynn1884SqDb', sans-serif
    letter-spacing: 0.02em
    text-transform: uppercase
    cursor: pointer
  .project__pdf-files
    width: $thresholdx2
    margin-left: $thresholdMinus
  @media (max-width: 767px)
    .project__pdf-files
      width: 100vw
</style>