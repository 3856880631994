import gsap from 'gsap'
import * as THREE from "three";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import fragmentShader from '../shaders/fragmentShader.glsl'
import vertexShader from '../shaders/vertexShader.glsl'
import fragmentShaderCenter from '../shaders/fragmentShaderCenter.glsl'
import vertexShaderCenter from '../shaders/vertexShaderCenter.glsl'

const isDevice = navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i)
  || /MacIntel/.test(navigator.platform) && window.innerWidth <= 1024

export class Slider {
  constructor(containerId, btnPerspectiveId, numByHeight = 3, numByWidth = 5, images, empty, ids) {
    this.render = this.render.bind(this);
    this.resize = this.resize.bind(this);
    //===================================================================================================
    this.bindEvents();
    //===================================================================================================
    this.options = {
      $container: document.getElementById(containerId),
      $button: document.getElementById(btnPerspectiveId),
      numByHeight,
      numByWidth,
      arrayBg: images.arrayBg,
      arrayText: images.arrayText,
      arrayCenter: images.arrayCenter,
      empty,
      ids
    }
    //===================================================================================================
    this.tl = null;
    //===================================================================================================
    this.tuning = {
      threshold: 0.06,
      opacity: 0.15,
      strengthInnerShadow: 1,
    }
    //===================================================================================================
    this.events = {
      touchmove: 'touchmove',
      move: 'mousemove',
      touchend: 'touchend',
      up: 'mouseup',
      touchstart: 'touchstart',
      down: 'mousedown',
      click: 'click',
      keydown: 'keydown'
    }
    //===================================================================================================
    this.store = {
      width: this.options.$container.clientWidth,
      height: this.options.$container.clientHeight,
      dpr: Math.min(devicePixelRatio, 2 || 1),
      orientation: (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
    }
    //===================================================================================================
    this.state = {
      x: 0,
      y: 0,
      iX: 0,
      iY: 0,
      center: {x: 0, y: 0, z: 0},
      distanceX: 0,
      distanceY: 0,
      hoveredObj: null,
      timeout: null,
      hoveredObjects: {},
      id: '',
      titleId: '',
      intersects: null,
      dragging: false,
      perspective: false,
      lock: false,
      isMove: false,
      onWheel: false,
      isClicked: false,
      centerChanged: false,
      toAbout: false,
      isAbout: false,
      hiddenLoading: false,
      onKey: false,
      flag: false
    }
    //===================================================================================================
    this.width = ((this.options.numByWidth + this.tuning.threshold) - this.tuning.threshold) / this.options.numByWidth
    this.wholeW = this.options.numByWidth * (this.width + this.tuning.threshold)
    this.height = ((this.options.numByHeight + this.tuning.threshold) - this.tuning.threshold) / this.options.numByHeight
    this.wholeH = this.options.numByHeight * (this.height + this.tuning.threshold)
    //===================================================================================================
  }

  //===================================================================================================
  bindEvents() {
    ['onDown', 'onMove', 'onUp', 'onClick', 'keyDown', 'onTouchmove', 'onTouchstart']
      .forEach(fn => this[fn] = this[fn].bind(this))
  }

  get id() {
    return this.state.id
  }

  set id(value) {
    this.state.id = value
  }

  //===================================================================================================
  on(canvas) {
    const {touchmove, move, touchend, up, touchstart, down, click, keydown} = this.events;
    canvas.addEventListener(touchmove, this.onTouchmove);
    window.addEventListener(touchend, this.onUp);
    canvas.addEventListener(touchstart, this.onTouchstart);
    canvas.addEventListener(down, this.onDown);
    canvas.addEventListener(move, this.onMove);
    window.addEventListener(up, this.onUp);
    canvas.addEventListener(click, this.onClick);
    document.addEventListener(keydown, this.keyDown);
  }

  //===================================================================================================
  async setup() {
    this.createScene();
    //===================================================================================================
    //Raycaster
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    //Group
    this.group = await this.createImagesGroup();
    //===================================================================================================
    //To center
    this.group.position.x -= ((this.options.numByWidth + ((this.options.numByWidth - 1) * this.tuning.threshold)) / 2) - 0.5;
    this.group.position.y -= ((3 + ((3 - 1) * this.tuning.threshold)) / 2) - 0.5;
    //===================================================================================================
    await this.scene.add(this.group);
    //===================================================================================================
    window.addEventListener("resize", this.resize);
    // Button listener
    this.tlBtn = gsap.timeline({
      onReverseComplete: () => {
        this.state.lock = false;
      }
    })
      .to(this.group.position, {duration: 1, x: -1.04}, 0)
      .to(this.group.rotation, {duration: 1, y: 0.35}, 0)
      .to(this.group.rotation, {duration: 1, x: -0.05}, 0)
      .to(this.camera.position, {
        duration: 1, z: 1, onComplete: () => {
          this.state.lock = false;
        }
      }, 0)
      .reversed(true);

    this.options.$button.addEventListener('click', () => {
      if (!this.state.lock) {
        this.state.perspective = !this.state.perspective
        this.state.lock = true;
        if (this.state.perspective) {
          this.group.children.forEach(e => {
            gsap.to(e.position, {
              duration: 0.5,
              x: e.position.x - 0.96
            })
          })
        } else {
          this.group.children.forEach(e => {
            gsap.to(e.position, {
              duration: 0.5,
              x: e.position.x + 0.96
            })
          })
        }

        //==================================================================
        this.tlBtn.reversed() ? this.tlBtn.play() : this.tlBtn.reverse();
      }
    });
    //==================================================================
    this.canv = this.options.$container.querySelector('canvas');
    //==================================================================

    this.onWheel(this.canv);

    //===================================================================================================
    this.render();
    //===================================================================================================
    this.on(this.canv);
    //===================================================================================================
  }

  //===================================================================================================
  getDistortionShaderDefinition() {
    return {

      uniforms: {
        "tDiffuse": {type: "t", value: null},
        "strength": {type: "f", value: 0},
        "height": {type: "f", value: 1},
        "aspectRatio": {type: "f", value: 1},
        "cylindricalRatio": {type: "f", value: 1}
      },

      vertexShader: [
        "uniform float strength;",          // s: 0 = perspective, 1 = stereographic
        "uniform float height;",            // h: tan(verticalFOVInRadians / 2)
        "uniform float aspectRatio;",       // a: screenWidth / screenHeight
        "uniform float cylindricalRatio;",  // c: cylindrical distortion ratio. 1 = spherical

        "varying vec3 vUV;",                // output to interpolate over screen
        "varying vec2 vUVDot;",             // output to interpolate over screen

        "void main() {",
        "gl_Position = projectionMatrix * (modelViewMatrix * vec4(position, 1.0));",

        "float scaledHeight = strength * height;",
        "float cylAspectRatio = aspectRatio * cylindricalRatio;",
        "float aspectDiagSq = aspectRatio * aspectRatio + 1.0;",
        "float diagSq = scaledHeight * scaledHeight * aspectDiagSq;",
        "vec2 signedUV = (2.0 * uv + vec2(-1.0, -1.0));",

        "float z = 0.5 * sqrt(diagSq + 1.0) + 0.5;",
        "float ny = (z - 1.0) / (cylAspectRatio * cylAspectRatio + 1.0);",

        "vUVDot = sqrt(ny) * vec2(cylAspectRatio, 1.0) * signedUV;",
        "vUV = vec3(0.5, 0.5, 1.0) * z + vec3(-0.5, -0.5, 0.0);",
        "vUV.xy += uv;",
        "}"
      ].join("\n"),

      fragmentShader: [
        "uniform sampler2D tDiffuse;",      // sampler of rendered scene?s render target
        "varying vec3 vUV;",                // interpolated vertex output data
        "varying vec2 vUVDot;",             // interpolated vertex output data

        "void main() {",
        "vec3 uv = dot(vUVDot, vUVDot) * vec3(-0.5, -0.5, -1.0) + vUV;",
        "gl_FragColor = texture2DProj(tDiffuse, uv);",
        "}"
      ].join("\n")

    };
  }

  //===================================================================================================
  createScene() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
    //===================================================================================================
    this.camera = new THREE.PerspectiveCamera(80, this.store.width / this.store.height, 0.01, 10);
    this.camera.aspect = this.store.width / this.store.height;
    this.camera.position.z = 1.9;
    //===================================================================================================
    this.renderer = new THREE.WebGLRenderer({antialias: true});
    this.renderer.setSize(this.store.width, this.store.height);
    this.renderer.setPixelRatio(this.store.dpr);
    this.renderer.autoClear = false;
    //===================================================================================================
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));
    //===================================================================================================
    this.effect = new ShaderPass(this.getDistortionShaderDefinition());
    this.composer.addPass(this.effect);
    this.effect.renderToScreen = true;
    //===============================================================
    if ((this.store.width / this.store.height) > 2 && this.store.width < 1200) {
      this.horizontalFOV = 125 - (6 * (this.store.width / this.store.height));
    } else if (this.store.width < 412 && (this.store.height / this.store.width) < 2.1) {
      this.horizontalFOV = 136
    } else {
      this.horizontalFOV = 125;
    }
    //===================================================================================================
    this.strength = 0;
    this.cylindricalRatio = 4;
    //===================================================================================================
    this.ratio = 2;
    this.height = Math.tan(THREE.MathUtils.degToRad(this.horizontalFOV) / 2) / this.ratio / 2;
    this.width = Math.tan(THREE.MathUtils.degToRad(this.horizontalFOV) / 2) / this.ratio / 2;
    //===================================================================================================
    this.camera.fov = Math.atan(this.height) * 2 * 180 / 3.1415926535;
    this.camera.updateProjectionMatrix();
    //===================================================================================================
    this.effect.uniforms["strength"].value = this.strength;
    this.effect.uniforms["height"].value = this.height;
    this.effect.uniforms["aspectRatio"].value = this.camera.aspect;
    this.effect.uniforms["cylindricalRatio"].value = this.cylindricalRatio;
    //===================================================================================================
    this.options.$container.appendChild(this.renderer.domElement);
  }

  //===================================================================================================
  async createImagesGroup() {
    const group = new THREE.Group();
    //==========================================================
    const startPointX = 0;
    const startPointY = (3 - 1) + (3 - 1) * this.tuning.threshold;
    const numberImages = this.options.numByWidth * this.options.numByHeight;
    //==========================================================
    let displacementX = startPointX;
    let displacementY = startPointY;
    //==========================================================
    const numCenter = (Math.ceil(3 / 2) * this.options.numByWidth) - Math.ceil(this.options.numByWidth / 2);
    //==========================================================
    // let numImg = 15

    for (let i = 0; i < numberImages; i++) {
      const geometry = new THREE.PlaneBufferGeometry(1, 1);
      geometry.computeBoundingBox();
      //==========================================================
      let material;
      if (i !== 7) {
        material = new THREE.ShaderMaterial({
          uniforms: {
            t1: {
              value: await new THREE.TextureLoader().load(this.options.arrayBg[i] || this.options.empty)
            },
            t2: {
              value: await new THREE.TextureLoader().load(this.options.arrayText[i])
            },
            color1: {
              value: new THREE.Color(0x000000)
            },
            color2: {
              value: new THREE.Color(0xffffff)
            },
            bboxMin: {
              value: geometry.boundingBox.min
            },
            bboxMax: {
              value: geometry.boundingBox.max
            },
            u_strength: {
              value: 0.
            },
            opacity: {
              value: 0.
            },
            blend: {
              value: 1.
            }
          },
          fragmentShader,
          vertexShader,
          transparent: true,
          opacity: 0.1
        });
      } else {
        material = new THREE.ShaderMaterial({
          uniforms: {
            t1: {
              value: await new THREE.TextureLoader().load(this.options.arrayCenter[0])
            },
            t2: {
              value: await new THREE.TextureLoader().load(this.options.arrayCenter[1])
            },
            t3: {
              value: await new THREE.TextureLoader().load(this.options.arrayCenter[2])
            },
            t4: {
              value: await new THREE.TextureLoader().load(this.options.arrayCenter[3])
            },
            u_strength: {
              value: 0.
            },
            opacity: {
              value: 0.
            },
            blend: {
              value: 1.
            },
            ellipseBorder: {
              value: 0.3
            },
            ellipseFill: {
              value: 0.
            }
          },
          fragmentShader: fragmentShaderCenter,
          vertexShader: vertexShaderCenter,
          transparent: true
        });
      }

      const mesh = new THREE.Mesh(geometry, material);

      if (i !== 7 && !this.options.arrayBg[i]) {
        mesh.noclick = true
      }
      else if (i !== 7) {
        mesh.uId = i + 1
        if (this.options.ids[i]) {
          mesh.titleId = this.options.ids[i]
        } else {
          mesh.titleId = i + 1
        }
      }
      if (i === 7) {
        mesh.u_position = 'center'
      }

      if (i !== 0) {
        displacementX += 1 + this.tuning.threshold;
      }

      if (i % this.options.numByWidth === 0 && i !== 0) {
        displacementY -= 1 + this.tuning.threshold;
        displacementX = startPointX;
      }
      //==========================================================
      mesh.position.x = displacementX;
      mesh.position.y = displacementY;
      //==========================================================
      if (i === numCenter) {
        this.state.center.x = mesh.position.x
        this.state.center.y = mesh.position.y
      }
      group.add(mesh);
    }
    return group;
  }

  //===================================================================================================
  //Event functions
  keyDown(e) {
    if (!this.state.isAbout && this.state.hiddenLoading && !this.state.isClicked) {
      this.state.onKey = true
      if (e.key === 'ArrowRight') {
        this.state.x -= 0.02
      } else if (e.key === 'ArrowLeft') {
        this.state.x += 0.02
      } else if (e.key === 'ArrowUp') {
        this.state.y -= 0.02
      } else if (e.key === 'ArrowDown') {
        this.state.y += 0.02
      }
    }
  }

  onClick(e, obj) {
    if (e) e.preventDefault()
    if (this.state.isMove) {
      this.group.children.forEach(el => {
        el.material.uniforms.opacity.value = 1;
        el.material.uniforms.u_strength.value = 0;
      })
      if (this.state.isClicked) {
        this.group.children.forEach(e => {
          e.material.uniforms.opacity.value = 1;
          e.material.uniforms.u_strength.value = 0;
        })
        this.state.isClicked = false;
        return;
      }
      //==========================================================
      if (!obj) {
        this.mouse.x = (e.clientX / this.store.width) * 2 - 1;
        this.mouse.y = -(e.clientY / this.store.height) * 2 + 1;
        //==========================================================
        this.raycaster.setFromCamera(this.mouse, this.camera);
        this.state.intersects = this.raycaster.intersectObjects(this.group.children);
        //==========================================================
        if (this.state.intersects.length > 0 && !this.state.intersects[0].object.noclick) {
          if (this.state.intersects[0].object && this.state.intersects[0].object.uId) {
            this.state.isClicked = true;
            this.canv.style.cursor = 'default';
            this.id = this.state.intersects[0].object.uId;
            this.state.titleId = this.state.intersects[0].object.titleId
            //==========================================================
            this.state.intersects[0].object.material.uniforms.u_strength.value = this.tuning.strengthInnerShadow;
            this.group.children.forEach(e => {
              if (e.id !== this.state.intersects[0].object.id) {
                e.material.uniforms.opacity.value = this.tuning.opacity;
              }
            })
            if (this.state.perspective) {
              this.state.centerChanged = true;
              this.state.distanceX = this.state.intersects[0].object.position.x - this.state.center.x + 0.96;
            } else {
              this.state.distanceX = this.state.intersects[0].object.position.x - this.state.center.x;
            }
            this.state.distanceY = this.state.intersects[0].object.position.y - this.state.center.y;
            //==========================================================
            this.state.x -= this.state.distanceX / 9
            this.state.y -= this.state.distanceY / 9
          } else if (!this.state.intersects[0].object.uId && isDevice) {
            if (this.state.flag) {
              let x = this.state.intersects[0].uv.x;
              let y = this.state.intersects[0].uv.y;
              let rr = Math.pow((x - 0.5), 2) + Math.pow((y - 0.5), 2);

              if (rr <= 0.25 * 0.25) {
                this.state.toAbout = true;
              }
            }
            this.state.intersects[0].object.material.uniforms.blend.value = 0
            this.state.flag = true
            if (this.state.timeout) {
              clearTimeout(this.state.timeout)
            }
            this.state.timeout = setTimeout(() => {
              this.state.intersects[0].object.material.uniforms.blend.value = 1
              this.state.flag = false
            }, 2000)
          } else if (!this.state.intersects[0].object.uId) {
            let x = this.state.intersects[0].uv.x;
            let y = this.state.intersects[0].uv.y;
            let rr = Math.pow((x - 0.5), 2) + Math.pow((y - 0.5), 2);

            if (rr <= 0.25 * 0.25) {
              this.state.toAbout = true;
            }
          }
          //==========================================================
          else {
            this.group.children.forEach(e => {
              e.material.uniforms.opacity.value = 1;
              e.material.uniforms.u_strength.value = 0;
            })
          }
        }
      } else {
        this.state.isClicked = true;
        this.canv.style.cursor = 'default';
        this.id = obj.uId;
        //==========================================================
        obj.material.uniforms.u_strength.value = this.tuning.strengthInnerShadow;
        this.group.children.forEach(e => {
          if (e.id !== obj.id) {
            e.material.uniforms.opacity.value = this.tuning.opacity;
          }
        })
        if (this.state.perspective) {
          this.state.centerChanged = true;
          this.state.distanceX = obj.position.x - this.state.center.x + 0.96;
        } else {
          this.state.distanceX = obj.position.x - this.state.center.x;
        }
        this.state.distanceY = obj.position.y - this.state.center.y;
        //==========================================================
        this.state.x -= this.state.distanceX / 9
        this.state.y -= this.state.distanceY / 9
      }
      //==========================================================
    }
  }

  onDown() {
    if (!this.state.isClicked && !this.state.onWheel) {
      this.state.dragging = true;
      this.state.isMove = true;
      //===========================================================
      gsap.to(this.effect.uniforms["strength"], {duration: 0.5, value: 1})
      gsap.to(this.effect.uniforms["cylindricalRatio"], {duration: 0.3, value: 0.7})
    }
  }

  onTouchstart(e) {
    if (!this.state.isClicked && !this.state.onWheel) {
      this.state.dragging = true;
      this.state.isMove = true;
      if (this.store.width < 425) {
        gsap.to(this.effect.uniforms["strength"], {duration: 0.5, value: 1.5})
        gsap.to(this.effect.uniforms["cylindricalRatio"], {duration: 0.5, value: 2})
      } else {
        gsap.to(this.effect.uniforms["strength"], {duration: 0.5, value: 1})
        gsap.to(this.effect.uniforms["cylindricalRatio"], {duration: 0.3, value: 0.7})
      }
      this.state.iX = e.changedTouches[0].clientX;
      this.state.iY = e.changedTouches[0].clientY;
    }
  }

  onUp() {
    if (!this.state.isClicked) {
      this.state.dragging = false
      gsap.to(this.effect.uniforms["strength"], {duration: 0.5, value: 0});
      gsap.to(this.effect.uniforms["cylindricalRatio"], {duration: 0.5, value: 0});
    }
  }

  onMove(e) {
    if (!this.state.isClicked) {
      this.state.onKey = false
      e.preventDefault();
      //==========================================================
      this.mouse.x = (e.clientX / this.store.width) * 2 - 1;
      this.mouse.y = -(e.clientY / this.store.height) * 2 + 1;
      //==========================================================
      this.raycaster.setFromCamera(this.mouse, this.camera);
      this.state.intersects = this.raycaster.intersectObjects(this.group.children, true);
      //==========================================================
      if (this.state.intersects.length > 0 && !this.state.intersects[0].object.noclick) {
        this.canv.style.cursor = 'pointer'
      } else {
        this.canv.style.cursor = 'grab'
      }
      //==========================================================
      let hoveredObjectUuids = this.state.intersects.map(el => el.object.uuid);
      //==========================================================
      for (let i = 0; i < this.state.intersects.length; i++) {
        this.state.hoveredObj = this.state.intersects[i].object;
        if (!this.state.intersects[i].object.uId) {
          this.canv.style.cursor = 'grab';
          let x = this.state.intersects[i].uv.x;
          let y = this.state.intersects[i].uv.y;
          let rr = Math.pow((x - 0.5), 2) + Math.pow((y - 0.5), 2);
          let border = this.state.intersects[i].object.material.uniforms.ellipseBorder
          let fill = this.state.intersects[i].object.material.uniforms.ellipseFill
          let flag = false

          if (!this.state.intersects[0].object.noclick) {
            if (rr <= 0.3 * 0.3 && !flag) {
              flag = true
              gsap.to(border, {duration: 0.5, value: 1})
              gsap.to(fill, {duration: 0.5, value: 1})
              this.canv.style.cursor = 'pointer';
            } else {
              flag = false
              gsap.to(border, {duration: 0.5, value: 0.3})
              gsap.to(fill, {duration: 0.5, value: 0.})
            }
          }
        }
        if (this.state.hoveredObjects[this.state.hoveredObj.uuid]) {
          continue;
        }
        //TO
        gsap.to(this.state.intersects[i].object.material.uniforms.blend, {
          duration: 0.5,
          value: 0.
        });
        this.state.hoveredObjects[this.state.hoveredObj.uuid] = this.state.hoveredObj;
      }

      for (let uuid of Object.keys(this.state.hoveredObjects)) {
        let idx = hoveredObjectUuids.indexOf(uuid);
        if (idx === -1) {
          let unhoveredObj = this.state.hoveredObjects[uuid];
          delete this.state.hoveredObjects[uuid];
          //BACK
          gsap.to(unhoveredObj.material.uniforms.blend, {
            duration: 0.5,
            value: 1.
          });
        }
      }
      //==========================================================
      if (!this.state.dragging) return
      //==========================================================
      this.canv.style.cursor = 'grabbing';
      //==========================================================
      this.state.isMove = false;
      //==========================================================
      this.state.x = e.movementX / 300;
      this.state.y = -e.movementY / 300;
    }
  }

  onTouchmove(e) {
    if (!this.state.isClicked) {
      this.state.onKey = false
      e.preventDefault();

      if (!this.state.dragging) return
      //==========================================================
      this.canv.style.cursor = 'grabbing';
      //==========================================================
      this.state.isMove = false;

      let moveX = e.changedTouches[0].clientX - this.state.iX;
      let moveY = e.changedTouches[0].clientY - this.state.iY;
      this.state.iX = e.changedTouches[0].clientX;
      this.state.iY = e.changedTouches[0].clientY;
      this.state.x += moveX / 500;
      this.state.y += -moveY / 500;
    }
  }

  onWheel(canvas) {
    canvas.addEventListener('wheel', e => {
      this.state.onKey = false
      e.preventDefault()
      if (!this.state.isClicked && !this.state.dragging) {
        this.state.onWheel = true;
        if (this.tl) this.tl.clear()
        this.tl = gsap.timeline()
        this.tl.to(this.effect.uniforms["strength"], {duration: 0.6, value: 1}, 0);
        this.tl.to(this.effect.uniforms["cylindricalRatio"], {
          duration: 0.3, value: 0.5, onComplete: () => {
            gsap.timeline().to(this.effect.uniforms["strength"], {duration: 0.8, value: 0}, 0);
            gsap.timeline().to(this.effect.uniforms["cylindricalRatio"], {
              duration: 0.8, value: 0, onComplete: () => {
                this.state.onWheel = false;
              }
            }, 0);
          }
        }, 0);
        this.state.x = -e.deltaX / 450;
        this.state.y = e.deltaY / 450;
        //==========================================================
      }
    })
  }

  //==========================================================
  calcPosX(state, pos) {
    return (state + pos + this.wholeW + this.width + this.tuning.threshold) % this.wholeW - this.width - this.tuning.threshold;
  }

  calcPosY(state, pos) {
    return (state + pos + this.wholeH + this.height + this.tuning.threshold) % this.wholeH - this.height - this.tuning.threshold;
  }

  //==========================================================
  render() {
    if (this.state.isClicked || this.state.onKey) {
      this.state.x *= 0.9;
      this.state.y *= 0.9;
    } else {
      this.state.x *= 0.7;
      this.state.y *= 0.7;
    }

    this.group.children.forEach(e => {
      e.position.x = this.calcPosX(this.state.x, e.position.x);
      e.position.y = this.calcPosY(this.state.y, e.position.y);
    })
    requestAnimationFrame(this.render);
    this.composer.render();
  }

  resize() {
    if (!this.state.isAbout) {
      this.store.width = this.options.$container.clientWidth;
      this.store.height = this.options.$container.clientHeight;
    } else {
      this.store.width = window.innerWidth;
      this.store.height = window.innerHeight;
    }
    if ((this.store.width / this.store.height) > 2 && this.store.width < 1200) {
      this.horizontalFOV = 125 - (6 * (this.store.width / this.store.height));
    } else if (this.store.width < 412 && (this.store.height / this.store.width) < 2.1) {
      this.horizontalFOV = 135
    } else {
      this.horizontalFOV = 125;
    }
    //===================================================================================================
    this.width = this.height = Math.tan(THREE.MathUtils.degToRad(this.horizontalFOV) / 2) / this.ratio / 2;
    //===================================================================================================
    this.camera.fov = Math.atan(this.height) * 2 * 180 / 3.1415926535;
    this.camera.updateProjectionMatrix();
    //===================================================================================================
    this.renderer.setSize(this.store.width, this.store.height);
    this.composer.setSize(this.store.width, this.store.height);
    //===================================================================================================
    this.camera.aspect = this.store.width / this.store.height;
    this.camera.updateProjectionMatrix();

    //===================================================================================================
    this.effect.uniforms["strength"].value = this.strength;
    this.effect.uniforms["height"].value = this.height;
    this.effect.uniforms["aspectRatio"].value = this.camera.aspect;
    this.effect.uniforms["cylindricalRatio"].value = this.cylindricalRatio;

    this.store.orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation
  }
}