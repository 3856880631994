<template>
  <h1 class="project__title">
    {{ title }}
  </h1>
</template>

<script>

export default {
  name: "Title",
  props: ['title']
}
</script>

<style lang="sass">
.project__title
	font-family: 'Galderglynn1884SqDb', sans-serif
	font-size: calc(60px + 23 * ((100vw - 320px) / (1920 - 320)))
	line-height: calc(46px + 28 * ((100vw - 320px) / (1920 - 320)))
	letter-spacing: 0.02em
	margin-bottom: 20px
	color: #000
</style>