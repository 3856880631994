<template>
  <div 
       class="openGalleryProject" 
       id="openGalleryProject"
  >
    <div class="openGalleryProject__close"
         @click="closeOpenGallery"
         v-if="!hide"
    >
      <span class="openGalleryProject__twix"></span>
      <span class="openGalleryProject__twix"></span>
    </div>
    <div class="openGalleryProject__slider" id="openGalleryProject__slider">
      <svg 
          @click="toLeft" 
          class="pag toLeft" 
          width="30" 
          height="55" 
          viewBox="45 51 30 80" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          v-show="!hide"
          v-if="blocks.length > 1"
      >
        <path d="M51.75 131L73 91L51.75 51" stroke="black" stroke-width="2"/>
      </svg>

      <!--Dynamic data-->

      <div class="openGalleryProject__center" id="openGalleryProject__center">
        <!-- IMAGE -->
        <img
          v-show="type === 'image'"
          class="openGalleryProject__center-img"
          :src="imgCenter" 
          alt=""
        >
        <!-- YOUTUBE -->
        <div class="container-iframe" v-if="type === 'youtube'">
          <youtube
              :video-id="imgCenter"
              ref="youtube"
              id="iframe"
          ></youtube>
          <div class="iframe-mask" v-show="!hideMask"></div>
        </div>
        <!-- VIDEO -->
        <video 
          v-if="type === 'video'"
          controls
          class="project__video" 
          :src="videoCenter"
          :poster="imgCenter"
        >
          Sorry, your browser doesn't support embedded videos,
          but don't worry, you can <a :href="videoCenter">download it</a>
          and watch it with your favorite video player!
        </video>
      </div>
      <!--#Dynamic data-->

      <svg
          @click="toRight" 
          class="pag toRight" 
          width="30" 
          height="55" 
          viewBox="45 51 30 80" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          v-if="blocks.length > 1"
          v-show="!hide"
      >
        <path
            d="M51.75 131L73 91L51.75 51"
            stroke="black"
            stroke-width="2"/>
      </svg>
    </div>
    <div class="openGalleryProject__description" id="openGalleryProject__description">
      <div class="openGalleryProject__description-box"
           @click.prevent="showing = !showing"
           >
        <!--Dynamic data-->
        <p
            class="openGalleryProject__description-text"
            id="openGalleryProject-text"
            :style="{webkitLineClamp: !showing ? '2' : 'unset'}">
          {{article}}
        </p>
        <!--#Dynamic data-->
        <svg
            class="openGalleryProject__description-svg"
            width="30px" height="8px"
            xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"
            :style="{transform: showing ? 'rotate(0deg)' : 'rotate(-180deg)'}"
            v-show="!hideArrow"
        viewBox="0 0 21.9 7.6" style="enable-background:new 0 0 21.9 7.6;" xml:space="preserve">
          <polygon points="11,7.6 0,1.8 0.9,0 11,5.3 21,0 21.9,1.8 "/>
        </svg>
      </div>
    </div>
    <div class="openGalleryProject__pagination-box" id="openGalleryProject__pag">
      <div class="openGalleryProject__pagination">
        <PaginationItem
            v-for="(s, i) in blocks"
            :key="i"
            :i="i"
            :s="s"
            :blocks="blocks"
            :imgCenter="imgCenter"
            @click.native="getImg(s, i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationItem from "./PaginationItem";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "OpenGalleryProject",
  props: ['blocks', 'source'],
  components: {
    PaginationItem
  },
  data: () => ({
    type: '',
    imgCenter: '',
    videoCenter: '',
    article: null,
    showing: false,
    dragging: false,
    hide: false,
    lockTimer: false,
    hideArrow: false,
    hideMask: false,
    isDevice: null,
    index: null,
    moveX: null,
    timeout: null,
    pagItem: null,
    pagElements: [],
    coordsImg: {
      x: 0
    }
  }),
  watch: {
    async article(){
      const p = await document.getElementById('openGalleryProject-text')
      this.hideArrow = p.scrollHeight <= 50
      const description = await document.getElementById('openGalleryProject__description')
      if (!this.article){
        description.style.padding = 'unset'
      }
      else {
        description.style.padding = '10px calc((14px + 10 * ((100vw - 320px) / (1920 - 320)) + ( (68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320))))) - (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320)))) ) / 2 - 22.5px ))'
      }
      const slider = await document.getElementById('openGalleryProject__slider')
      const pag = document.getElementById('openGalleryProject__pag')
      pag.style.marginTop = description.offsetHeight + 'px'
      slider.style.height = `calc(100% - (10px + 46px + 24 * ((100vw - 320px) / (1920 - 320))) - ${description.offsetHeight}px)`
    },
    type(){
      if (this.isDevice){
        this.$nextTick(() => {
          if (this.type === 'youtube'){
            this.$refs.youtube.player.addEventListener('onStateChange', e => {
              if (e.data === 1){
                this.hideMask = true
              }
              else if (e.data === 2){
                this.hideMask = false
              }
            })
          }
          else if (this.$refs.youtube){
            this.$refs.youtube.player.removeEventListener('onStateChange', e => {
              if (e.data === 1){
                this.hideMask = true
              }
              else if (e.data === 2){
                this.hideMask = false
              }
            })
          }
        })
      }
    }
  },
  methods: {
    toCenter(path){
      this.showing = false
      let windowCenter = window.innerWidth / 2
      this.pagElements.forEach(e => {
        if (path === e.__vue__.path || path === e.__vue__.s.path){
          let elX = e.getBoundingClientRect().x + e.offsetWidth / 2
          let diff = elX - windowCenter
          gsap.to(this.pagItem, {
            duration: 0.6,
            scrollTo: {x: this.pagItem.scrollLeft + diff, autoKill: false}
          })
        }
      })
    },
    closeOpenGallery(){
      this.$emit('isOpenGallery', false)
      clearTimeout(this.timeout)
      this.hide = false
    },
    async getImg(s, i){
      this.imgCenter = s.path
      this.article = s.description
      if (s.video){
        this.videoCenter = await this.$store.dispatch(
            'fetchMedia',
            {path: s.video}
        )
      }
      this.index = i
      this.type = s.type

      //scroll to center
      this.toCenter(this.imgCenter)
    },
    async toLeft(){
      if (this.index - 1 < 0){
        this.index = this.blocks.length
      }
      this.imgCenter = this.blocks[this.index - 1].path
      this.article = this.blocks[this.index - 1].description
      if (this.blocks[this.index - 1].video){
        this.videoCenter = await this.$store.dispatch(
            'fetchMedia',
            {path: this.blocks[this.index - 1].video}
        )
      }
      this.type = this.blocks[this.index - 1].type
      this.index--

      //scroll to center
      this.toCenter(this.imgCenter)
    },
    async toRight(){
      if (this.index + 1 === this.blocks.length){
        this.index = -1
      }
      this.imgCenter = this.blocks[this.index + 1].path
      this.article = this.blocks[this.index + 1].description
      if (this.blocks[this.index + 1].video){
        this.videoCenter = await this.$store.dispatch(
            'fetchMedia',
            {path: this.blocks[this.index + 1].video}
        )
      }
      this.type = this.blocks[this.index + 1].type
      this.index++

      //scroll to center
      this.toCenter(this.imgCenter)
    },
    keysFunction(e){
      if (e.key === 'ArrowRight'){
        this.toRight()
      }
      else if (e.key === 'ArrowLeft'){
        this.toLeft()
      }
    }
  },
  async mounted(){
    const isDevice = navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    || /MacIntel/.test(navigator.platform) && window.innerWidth <= 1024

    this.imgCenter = this.source.path
    this.article = this.source.description

    if (this.source.video){
      console.log(this.source.video)
      this.videoCenter = await this.$store.dispatch('fetchMedia', {path: this.source.video})
    }
    this.index = this.blocks.indexOf(this.source)
    this.type = this.source.type

    this.pagItem = document.querySelector('.openGalleryProject__pagination-box')
    this.pagElements = this.pagItem.querySelectorAll('.openGalleryProject__pagination-item')

    //scroll to center
    this.toCenter(this.imgCenter)

    this.timeout = setTimeout(() => {
      this.hide = true;
    }, 2000);
    //hideTimer
    if (!isDevice){
      const openGalleryProject = document.getElementById('openGalleryProject')
      openGalleryProject.addEventListener('mousemove', () => {
        clearTimeout(this.timeout)
        this.hide = false;

        if (!this.lockTimer){
          this.timeout = setTimeout(() => {
            this.hide = true;
          }, 2000);
        }
      })
    }

    //keydown
    document.addEventListener('keydown', this.keysFunction)

    //pag mouseenter
    const pag = document.querySelectorAll('.pag')

    pag.forEach( e => {
      e.addEventListener('mouseenter', () => {
        this.lockTimer = true
      })
      e.addEventListener('mouseleave', () => {
        this.lockTimer = false
      })
    })
    const slider = document.getElementById('openGalleryProject__slider')

    slider.addEventListener('touchstart', e => {
      e.preventDefault()
      clearTimeout(this.timeout)
      this.hide = false;
      this.dragging = true;
      this.coordsImg.x = e.changedTouches[0].clientX;
    })
    slider.addEventListener('touchmove', e => {
      e.preventDefault()
      if (!this.dragging) return;
      if (e.changedTouches[0].clientX - this.coordsImg.x < -50 || e.changedTouches[0].clientX - this.coordsImg.x > 50){
        this.moveX = e.changedTouches[0].clientX - this.coordsImg.x;
      }
      else {
        this.moveX = 0
      }
    })
    slider.addEventListener('touchend', () => {
      this.dragging = false;
      this.timeout = setTimeout(() => {
        this.hide = true;
      }, 2000);
      if (this.moveX < -50){
        this.toRight()
      }
      else if (this.moveX > 50){
        this.toLeft()
      }
      this.moveX = 0
    })
  }
}
</script>

<style lang="sass">
$heightPag: calc(10px + 46px + 24 * ((100vw - 320px) / (1920 - 320)))
$height: calc(100% - (10px + 46px + 24 * ((100vw - 320px) / (1920 - 320))) - 66px)
$pagination: calc(46px + 24 * ((100vw - 320px) / (1920 - 320)))
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
$top: calc((14px + 10 * ((100vw - 320px) / (1920 - 320)) + ( (68px + (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320))))) - (2 * (14px + 10 * ((100vw - 320px) / (1920 - 320)))) ) / 2 - 22.5px ))
.openGalleryProject
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100%
  user-select: none

  z-index: 3
  background-color: #fff
  .openGalleryProject__t-shadow
    position: absolute
    height: 100%
    width: 100%
    pointer-events: none
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 20%)
  .openGalleryProject__close
    position: absolute
    right: 15px
    top: 15px
    height: 45px
    width: 45px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-radius: 50%
    cursor: pointer
    z-index: 3
    transition: 0.5s ease
    background-color: hsla(0, 0%, 88%, 0.56)
    &:hover
      background-color: #e4e4e4
    @media (min-width: 768px)
      top: $top
      right: $threshold
    .openGalleryProject__twix
      position: absolute
      display: block
      width: 25px
      height: 2px 
      background-color: #000
      &:first-child
        transform: rotate(45deg)
      &:last-child
        transform: rotate(-45deg)
  .openGalleryProject__slider
    position: relative
    display: flex
    align-items: center
    width: 100%
    height: $height
    @media (min-width: 768px)
      padding: 0 $threshold
    .pag
      display: none
      transition: 0.5s ease
      &:hover
        opacity: 0.5
      @media (min-width: 768px)
        display: block
    .toLeft
      cursor: pointer
      transform: rotate(180deg)
    .openGalleryProject__center
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      @media(min-width: 768px)
        margin: 0 25px
      .container-iframe
        position: relative
        width: 100%
        height: 100%
        max-width: 1120px
        max-height: 630px
        overflow: hidden
        #iframe
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
        .iframe-mask
          position: absolute
          top: 50%
          transform: translateY(-50%)
          height: 75%
          width: 100%
          z-index: 3
          clip-path: polygon(0% 0%, 0% 100%, 42.5% 100%, 42.5% 42.5%, 57.5% 42.5%, 57.5% 57.5%, 42.5% 57.5%, 42.5% 100%, 100% 100%, 100% 0%)
          @media (min-width: 768px)
            display: none

      .openGalleryProject__center-img
        width: unset
        height: unset
        max-height: 100%
        max-width: 100% 
      .project__video
        max-height: 100%
        max-width: 100%
    .toRight
      cursor: pointer

  .openGalleryProject__description
    position: absolute
    bottom: $heightPag
    left: 0
    width: 100%
    min-height: auto
    max-height: 300px
    overflow: auto
    padding: 10px $threshold
    border: 1px solid #00000010
    background-color: #fff
    z-index: 4
    .openGalleryProject__description-box
      display: flex
      position: relative
      left: 50%
      transform: translateX(-50%)
      cursor: pointer
      @media (min-width: 768px)
        width: calc(60% +  30px)
      
      .openGalleryProject__description-text
        font-size: 16px
        display: -webkit-box
        -webkit-box-orient: vertical
        max-width: calc(100% - 60px)
        margin-right: 30px
        overflow: hidden

      .openGalleryProject__description-svg
        position: sticky
        top: 20px
        bottom: auto
  .openGalleryProject__pagination-box
    display: flex
    align-items: center
    padding-bottom: 10px
    overflow-x: scroll
    @media (min-width: 768px)
        padding: 5px
        background-color: #F2F2F2
    .openGalleryProject__pagination
      display: flex
      align-items: center
      .openGalleryProject__pagination-item
        position: relative
        width: $pagination
        height: $pagination
        margin-right: 5px
        background-color: #000

        cursor: pointer
        .openGalleryProject__pagination-img
          height: 100%
          width: 100%   
          background-position: center
          background-size: cover
          opacity: 0.8
          &:hover
            opacity: 1   
        @media (min-width: 768px)
          margin-right: 5px
        
        .openGalleryProject__pagination-img
          opacity: 0.8
          &-active
            opacity: 1
</style>