<template>
  <div class="project__gallery">
    <div
        class="project__gallery-header"
        @click.prevent="isGalleryHandler">
      Галерея
      <svg width="30px" height="8px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           viewBox="0 0 21.9 7.6" style="enable-background:new 0 0 21.9 7.6;" xml:space="preserve"
           :style="{transform: isGallery ? 'rotate(0deg)' : 'rotate(-90deg)'}"
      >
      <polygon points="11,7.6 0,1.8 0.9,0 11,5.3 21,0 21.9,1.8 "/>
      </svg>
    </div>
    <div
        class="project__gallery-main"
        :style="{'grid-template-rows': gridRows}"
        v-if="isGallery"
    >
      <GalleryBlocks
        @source="getSource"
        @isOpenGallery="isOpenGalleryHandler"
        v-for="(s, i) in blocks"
        :key="i"
        :blocks="s"
      />
    </div>
    <OpenGalleryProject
        v-if="isOpenGallery"
        @isOpenGallery="isOpenGalleryHandler"
        :blocks="blocks"
        :source="source"
    />
  </div>
</template>

<script>
import GalleryBlocks from './GalleryBlocks'
import OpenGalleryProject from './OpenGalleryProject'
export default {
  name: "Gallery",
  props: {
    blocks: Array
  },
  components: {
    GalleryBlocks, OpenGalleryProject
  },
  data: () => ({
    source: Object,
    isGallery: false,
    isOpenGallery: false,
    gridRows: '',
    length: null
  }),
  methods: {
    getSource(e){
      this.source = e
    },
    isGalleryHandler(){
      this.isGallery = !this.isGallery
      this.$emit('isGallery', this.isGallery)
    },
    isOpenGalleryHandler(e){
      this.isOpenGallery = e
      this.$emit('isOpenGallery', this.isOpenGallery)
    }
  },
  mounted() {
    if (this.blocks){
      this.length = Math.ceil(this.blocks.length / 3)

      if (window.innerWidth >= 768){
        this.gridRows = `repeat(${this.length}, calc((448px + 52 * ((100vw - 320px) / (1920 - 320))) / 3.3))`
      }
      else {
        this.gridRows = `repeat(${this.length}, calc(100vw / 3.3))`
      }
    }


    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768){
        this.gridRows = `repeat(${this.length}, calc((448px + 52 * ((100vw - 320px) / (1920 - 320))) / 3.3))`
      }
      else {
        this.gridRows = `repeat(${this.length}, calc(100vw / 3.3))`
      }
    })
  }
}
</script>

<style lang="sass">
$thresholdMinus: calc(-1 * (20px + 15 * ((100vw - 320px) / (1920 - 320))))
$thresholdx2: calc(448px + 52 * ((100vw - 320px) / (1920 - 320)))
.project__gallery
  .project__gallery-header
    display: flex
    justify-content: space-between
    align-items: center
    height: 60px

    text-align: center
    font-size: 28px
    font-family: 'Galderglynn1884SqDb', sans-serif
    letter-spacing: 0.02em
    text-transform: uppercase
    cursor: pointer
  .project__gallery-main
    width: $thresholdx2
    margin-left: $thresholdMinus
    display: grid
    grid-template-columns: repeat(3, calc(100vw / 3.3))
    grid-gap: 8px
    justify-content: center
    @media (min-width: 768px)
      grid-template-columns: repeat(3, calc((448px + 52 * ((100vw - 320px) / (1920 - 320))) / 3.3))
@media (max-width: 767px)
  .project__gallery-main
    width: 100vw !important
</style>