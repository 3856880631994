<template>
  <a :href="link" class="project__link">{{ link }}</a>
</template>

<script>

export default {
  name: "Link",
  props: ['link']
}
</script>

<style lang="sass">
.project__link
  font-family: 'Galderglynn1884SqRg', sans-serif
  font-size: 24px
  letter-spacing: 0.04em
  color: #9E9E9E
  transition: 0.5s ease

  display: block
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  &:hover
    color: #9E9E9E95
</style>