<template>
  <div class="project__description">
    <div
        class="project__text"
        id="project__text"
        v-html="description"
    />
  </div>
</template>

<script>

export default {
  name: "Description",
  props: ['description'],
  data: () => ({
    showBtn: false
  })
}
</script>

<style lang="sass">
.project__description
  margin-top: 20px
  margin-bottom: 25px
  color: #000

  .project__text
    margin-bottom: 20px

    ul, ol
      margin-left: 32px

  .project__btn
    display: block
    width: max-content
    padding: 6px 20px

    font-family: 'Galderglynn1884SqLt', sans-serif
    font-weight: 300
    font-size: 19px
    line-height: 23px
    letter-spacing: 0.05em
    background: #FFFFFF
    color: #000
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15)
    transition: 0.5s ease

    &:hover
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1)
</style>