<template>
  <div id="socket" >
    <Title :title="title" v-if="title"/>
    <Link :link="link" v-if="link"/>
    <Description :description="description" v-if="description"/>
    <hr v-if="description">
    <Pdf :pdfFiles="pdfFiles" v-if="pdfFiles.length"/>
    <hr v-if="pdfFiles.length">
    <Gallery
        v-if="blocks.length && !blocksLoading"
        :blocks="blocks"
        :openGalleryProject="openGalleryProject"
        @isOpenGallery="getIsOpenGallery"
        @isGallery="getIsGallery"
    />
    <div v-if="blocksLoading" class="lds-dual-ring"/>
    <hr v-show="!isGallery && blocks.length">
    <OpenProject
        :projectBtnLink="projectBtnLink"
        v-show="clicked && projectBtnLink && !showMenu && !isOpenGallery"
    />
  </div>
</template>

<script>
import Title from './partsProject/Title'
import Link from './partsProject/Link'
import Description from "./partsProject/Description";
import Pdf from "./partsProject/Pdf";
import Gallery from "./partsProject/Gallery";
import OpenProject from "./partsProject/OpenProject";
export default {
  name: "ProjectSocket",
  props: {
    title: String,
    link: String,
    description: String,
    pdfFiles: Array,
    blocks: Array,
    blocksLoading: Boolean,
    openGalleryProject: Boolean,
    projectBtnLink: String,
    showMenu: Boolean
  },
  data: () => ({
    isGallery: false,
    isOpenGallery: false,
    clicked: false
  }),
  components: {
    Title, Link, Description, Pdf, Gallery, OpenProject
  },
  watch: {
    '$parent.$parent.$parent.clicked'(){
      this.clicked = this.$parent.$parent.$parent.clicked
    }
  },
  methods: {
    getIsOpenGallery(e){
      this.isOpenGallery = e
      this.$emit('isOpenGallery', e)
    },
    getIsGallery(e){
      this.isGallery = e
    }
  }
}
</script>

<style>
hr {
  width: calc(100% + 40px);
  border: 1px solid #f0f0f0;
  margin-left: -20px;
}
.lds-dual-ring {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  width: 45px;
  height: 60px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 24px;
  height: 24px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>