<template>
  <a :href="pdf.link" target="_blank" class="project__pdfFiles">
    <p class="project__pdfFiles-link">{{ pdf.name.replace('.pdf', '') }}</p>
    <svg class="project__search" height="30px" width="30px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 31 31" style="enable-background:new 0 0 31 31;" xml:space="preserve">
    <path class="view-background" d="M15.5,30.5c8.3,0,15-6.7,15-15c0-8.3-6.7-15-15-15c-8.3,0-15,6.7-15,15C0.5,23.8,7.2,30.5,15.5,30.5z"/>
      <path class="view-border" d="M15.5,31C7,31,0,24,0,15.5S7,0,15.5,0S31,7,31,15.5S24,31,15.5,31z M15.5,1C7.5,1,1,7.5,1,15.5S7.5,30,15.5,30
      S30,23.5,30,15.5S23.5,1,15.5,1z"/>
      <path class="view-icon" d="M20.7,10.3c-2.6-2.6-6.7-2.6-9.3-0.1c-1.2,1.2-1.9,2.9-1.9,4.6c0,1.4,0.5,2.7,1.3,3.8L8.6,21l1.4,1.4l2.3-2.3
      c1.1,0.8,2.5,1.3,3.9,1.3c0,0,0,0,0.1,0c1.7,0,3.3-0.7,4.6-1.9C23.3,17,23.3,12.9,20.7,10.3z M19.4,18.2c-0.8,0.8-2,1.3-3.2,1.3
      c-1.2,0-2.4-0.5-3.2-1.4s-1.4-2-1.4-3.2c0-1.2,0.4-2.3,1.3-3.2c0.9-0.9,2-1.3,3.2-1.3c1.2,0,2.4,0.5,3.3,1.4
      C21.1,13.5,21.1,16.4,19.4,18.2z"/>
  </svg>
  </a>
</template>

<script>

export default {
  name: "PdfFiles",
  props: ['pdf']
}
</script>

<style lang="sass">
$threshold: calc(20px + 15 * ((100vw - 320px) / (1920 - 320)))
.project__pdfFiles
  display: block
  position: relative
  padding: 12px $threshold
  width: 100%
  cursor: pointer
  &:hover > p
    text-decoration: underline
  &:hover > svg .view-background
    fill: none
  &:hover > svg .view-border
    fill: #000
  .project__pdfFiles-link
    color: #000
    max-width: calc(100% - 45px)
  .view-border
    fill: none
  .view-background
    fill: #C4C4C450
  .project__search
    position: absolute
    right: $threshold
    top: 50%
    transform: translateY(-50%)
</style>