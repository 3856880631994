<template>
  <div class="portfolio" id="portfolio">
    <div
      class="portfolio__projects"
      id="projects"
      :class="{'portfolio__projects-active': clicked}"
    >
      <div class="shadow t-black-shadow"></div>
      <div class="shadow b-black-shadow"></div>
    </div>
    <div
      class="portfolio__aboutProject"
      id="aboutProject"
      :class="{'project-active': !clicked}"
    >
      <ProjectLayout
          @clicked="closeHandler"
          @isOpenGallery="getIsOpenGallery"
          @projectBtnLink="getProjectBtnLink"
          :showMenu="showMenu"
          :id="id"
          :language="language"
      ></ProjectLayout>
      <div
          class="shadow-img-t"
          v-show="clicked">
        <img
            class="shadow-img"
            src="../assets/images/shadow/shadow.png"
            alt="">
      </div>
      <div
          class="shadow-img-b"
          v-show="clicked && !projectBtnLink"
      >
        <img class="shadow-img" src="../assets/images/shadow/shadow.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import ProjectLayout from "../components/ProjectLayout";

import {Slider} from '../lib/slider.js'

import c3 from '../assets/images/center/3.png'
import c4 from '../assets/images/center/4.png'
import empty from  '../assets/images/empty.png'
import gsap from "gsap";

export default {
  name: "Home",
  props: ['showMenu', 'hideLoading'],
  components: {
    ProjectLayout
  },
  data: () => ({
    id: null,
    slider: null,
    clicked: false,
    perspective: false,
    isOpenGallery: false,
    projectBtnLink: '',
    language: null,
    percent: 0,
    titleId: '',
    arrayBg: {},
    arrayText: {},
    arrayCenter: [],
    slides: [],
    ids: [],
    fetchingCounter: 0,
    fetchingCounterMax: 0
  }),
  watch: {
    hideLoading(){
      this.slider.state.hiddenLoading = this.hideLoading
    },
    percent(){
      if (this.percent !== 0){
        this.$emit('percent', this.percent)
      }
    },
    'slider.id'(){
      if (this.slider.id){
        this.id = this.slider.id
        this.titleId = this.slider.state.titleId
        if (this.titleId) this.$router.push(`/projects/${this.titleId}`)
      }
    },
    'slider.state.isClicked'(){
      this.onSlideClick(this.slider.state.isClicked)
    },
    'slider.state.perspective'(){
      this.perspective = this.slider.state.perspective
      this.$emit('perspective', this.perspective)
    },
    'slider.state.toAbout'(){
      if (this.slider.state.toAbout){
        this.$router.push('/about')
        this.slider.state.toAbout = false
      }
    },
    $route(path){
      this.slider.state.isAbout = path.name === 'About';
    },
    async fetchingCounter(){
      if (this.fetchingCounter === this.fetchingCounterMax) {
        this.slider = new Slider('projects', 'btn', this.slides.length / 5, 5, {
          arrayBg: Object.values(this.arrayBg),
          arrayText: Object.values(this.arrayText),
          arrayCenter: this.arrayCenter
        }, empty, this.ids)

        await this.slider.setup()
      }
    }
  },
  async mounted() {
    const languages = await this.$store.dispatch('fetchLanguages')
    let LANGUAGE

    let lLanguages = []

    let tempArr = []

    Object.keys(languages).forEach(key => {
      lLanguages.push(languages[key])
      if (languages[key].visible) {
        tempArr.push({
          title: languages[key].name,
          flag: languages[key].images.flag,
          id: key,
          pos: languages[key].pos
        })
      }
    })

    lLanguages.sort((a, b) => a.pos - b.pos)

    try {
      const localLanguage = JSON.parse(localStorage.getItem('language'))

      if (localLanguage && languages[localLanguage.id] && languages[localLanguage.id].visible) {
        LANGUAGE = localLanguage
      }
      else {
        // eslint-disable-next-line no-unused-vars
        for (let lan of lLanguages) {
          if (lan.visible) {
            LANGUAGE = {id: lan.id, name: lan.name}
            break
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-unused-vars
      for (const [_, value] of Object.entries(languages)) {
        if (value.visible){
          LANGUAGE = {id: value.id, name: value.name}
          break
        }
      }
    }

    const currLanguage = languages[LANGUAGE.id]
    this.language = currLanguage

    const c1 = await this.$store.dispatch('fetchMedia', {path: currLanguage.images.ourProjects})
    const c2 = await this.$store.dispatch('fetchMedia', {path: currLanguage.images.about})

    this.slides = currLanguage.pages.portfolio.slides

    this.fetchingCounterMax = (this.slides.length - 1) * 2

    if (this.slides){
      let partPercent = 5 / (this.slides.length + 3)
      for (let block of this.slides) {
        if (block.id !== 7){
          if (block.loaded) {
            this.$store.dispatch('fetchMedia', {path: block.images.front}).then(data => {
              this.arrayText[block.id] = data
              this.fetchingCounter++
            })
            this.$store.dispatch('fetchMedia', {path: block.images.back}).then(data => {
              this.arrayBg[block.id] = (data)
              this.percent += partPercent
              this.fetchingCounter++
            })
            if (block.uid) this.ids[block.id] = block.uid
          } else {
            this.arrayBg[block.id] = null
            this.arrayText[block.id] = null
          }
        }
        else {
          this.arrayBg[block.id] = null
          this.arrayText[block.id] = null
          this.percent += partPercent
        }
      }
    }
    else {
      this.percent = 5
    }

    this.arrayCenter = [c1, c2, c3,c4];

    this.$parent.$children.forEach(e => {
      if (e.$refs.about){
        this.slider.group.children.forEach(e =>{
          if (!e.u_position){
            gsap.to(e.material.uniforms.opacity, {
              delay: this.randomNumber(0.5, 1.5),
              duration: 0.25,
              ease: 'linear',
              value: 1
            })
          }
          else{
            gsap.to(e.material.uniforms.opacity, {
              delay: 1.5,
              ease: 'linear',
              duration: 0.25,
              value: 1
            })
          }
        })
      }
    })
    setTimeout(() => {
      if (this.$route.params.id) {
        this.id = Number.parseInt(this.$route.params.id)
                // this.onSlideClick(true)
        this.slider.state.isMove = true
        if (!isNaN(this.id)) {
          this.slider.onClick(null, this.slider.group.children[this.id - 1])
        }
        else {
          this.titleId = this.$route.params.id
          this.id = this.slides.find((o, i) => o.titleId === this.titleId && i)
          this.slider.onClick(null, this.slider.group.children.find(o => o.titleId === this.titleId))
        }
      }
    }, 4100)
  },
  methods: {
    randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
    closeHandler(e){
      this.clicked = e
      this.slider.group.children.forEach(e => {
        e.material.uniforms.opacity.value = 1;
        e.material.uniforms.u_strength.value = 0;
      })
      this.slider.state.isClicked = e
    },
    getIsOpenGallery(e){
      this.isOpenGallery = e
    },
    getProjectBtnLink(e){
      this.projectBtnLink = e
    },
    onSlideClick(isClicked) {
      if (this.clicked) {
        this.slider.id = null
        this.$router.push(`/`)
      }

      this.clicked = isClicked
      this.$emit('clicked', this.clicked)

      this.$nextTick(() => {
        if (this.clicked){
          this.$emit('projectBtnLink', this.projectBtnLink)
        }
        else {
          this.$emit('projectBtnLink', false)
        }
      })
    }
  }
}
</script>

<style lang="sass">
$width: calc(448px + 52 * ((100vw - 320px) / (1920 - 320)))
$lWidth: calc((448px + 52 * ((100vw - 320px) / (1920 - 320))) / -2)
.portfolio
  position: relative
  overflow: hidden
  display: flex
  height: 100%
  width: 100%
  .portfolio__projects
    position: absolute
    width: 100%
    height: 100%
    transition: 0.55s ease
    overflow: hidden
  @media (min-width: 768px)
    .portfolio__projects-active
      transform: translateX($lWidth)
  .portfolio__aboutProject
    position: absolute
    top: 0
    right: 0
    width: inherit
    height: inherit
    transition: 0.55s ease
    overflow-x: hidden
    overflow-y: auto
    background-color: #fff
    @media (min-width: 768px)
      width: $width    
    .shadow-absolute
      pointer-events: none
      position: relative
      left: 0
      top: 0
      width: inherit
      height: inherit
.project-active
  transform: translateX(100%)
  @media (min-width: 768px)
    transform: translateX($width)

.snackbar
  position: absolute
  left: 50%
  bottom: 24px
  transform: translateX(-50%)
  padding: 8px 16px
  border-radius: 8px
  color: #fff
  //background-color: #fff
  font-family: "Galderglynn1884SqRg", sans-serif
  font-size: 22px
</style>