<template>
  <a
      :href="projectBtnLink"
      class="open-project">
    Открыть проект
  </a>
</template>

<script>

export default {
  name: "OpenProject",
  props: ['projectBtnLink']
}
</script>

<style lang="sass">
$width: calc(448px + 52 * ((100vw - 320px) / (1920 - 320)))
$thresholdM: calc(-1 * (20px + 15 * ((100vw - 320px) / (1920 - 320))))
.open-project
  display: block
  position: fixed
  bottom: 0
  right: 0
  width: 100%
  margin-left: $thresholdM
  padding: 10px 0
  font-family: 'Galderglynn1884SqDb', sans-serif
  letter-spacing: 0.03em
  font-size: 33px
  text-align: center
  text-transform: uppercase
  color: #000
  background-color: #F0EFEF
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2)
  z-index: 3
  transition: 0.55s ease
  &:hover
    background-color: #fff
  @media(min-width: 768px)
    width: $width
</style>