<template>
  <div class="openGalleryProject__pagination-item">
    <div
        class="openGalleryProject__pagination-img"
        :class="{'openGalleryProject__pagination-img-active': imgCenter === blocks[i].path}"
        :style="{ backgroundImage: 'url(' + path + ')' }"
    >
      <img 
          v-show="s.type === 'youtube' || s.type === 'video'"
          src="../../assets/images/play.png"
          alt=""
          class="project__play" 
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "PaginationItem",
  props: ['i', 's', 'blocks', 'imgCenter'],
  data: () => ({
    path: ''
  }),
  mounted() {
    if (this.s.type === 'image' || this.s.type === 'video') {
      this.path = this.s.path
    } else if (this.s.type === 'youtube') {
      this.path = `//img.youtube.com/vi/${this.s.path}/maxresdefault.jpg`
    }
  }
}
</script>